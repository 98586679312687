<template>
  <div>
      <!-- <router-view /> -->
      <!-- <van-tabbar v-model="$route.meta.activeNum"> -->
      <van-tabbar v-model="active" active-color="#1989fa" inactive-color="#656466" @change="onChange">
          <van-tabbar-item to="/index" icon="home-o">首页</van-tabbar-item>
          <van-tabbar-item to="/course" icon="label-o">选课</van-tabbar-item>
          <van-tabbar-item to="/learning" icon="play-circle-o">学习</van-tabbar-item>
          <van-tabbar-item to="/Question" icon="edit">题库</van-tabbar-item>
          <van-tabbar-item to="/user" icon="user-o">我的</van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'AppTabbar',
  // data () {
  //   return {
  //   }
  // },
  // setup() {
  //   const active = ref(0);
  //   return { active };
  // },
  // computed: {
  //   active() {
  //     return this.$route.meta.activeNum
  //   }
  // }

  //通过这个获取到网页的路径 ps:this.$route(只读)，this.$router(跳转)
  mounted() {
    console.log(this.$route.path);
  },
    computed: {
    active: {
      get(){
        switch (this.$route.path) {
        case "/index":
          return 0;
        case "/course":
          return 1;
        case "/learning":
          return 2;
        case "/Question":
          return 3;
        case "/user":
          return 4;
        default:
          break;
      }
      },
      set(){}
    }
  }

}
</script>
 
<style lang = "less" scoped>

</style>