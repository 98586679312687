import axios from "axios"
import Qs from 'qs'
import VueRouter from 'vue-router'
import Vue from "vue";
import {Toast} from "vant";
import store from '../store/index'
import main from '../store/index'
Vue.use(VueRouter)

const instance = axios.create({
    // baseURL: "data.json",
    // baseURL: "http://kumanxuan1.f3322.net:8001",
    // baseURL: "http://sg.edu.org",
    baseURL: 'https://admin.ksyt.com.cn/school',
    // baseURL: 'http://education.org/school',
    timeout: 50000
})
// 两种拦截器的区别
// 封装axios拦截器,拦截的是请求,一般用在需要在请求中添加请求信息(例如:请求头)
// 路由拦截:拦截的是页面,可以决定用户有没有权利访问某个页面


// 拦截器
instance.interceptors.request.use(config => {
    store.commit('showLoading')
    // 什么时候执行的??    发请求之前执行这个函数
    // 可以判断用户有没有登录,  如果没有登录就直接return, 请求就不会执行
    // console.log("config", config); // 本次请求的一些信息
    let token = localStorage.getItem("token")
    // console.log(token);
    config.headers["Content-Type"] = 'application/x-www-form-urlencoded'
    if (token) {

        // 携带登录凭证发起请求
        if(Vue.prototype.loginTimer == null) {

            Vue.prototype.loginTime();
        }
        config.headers["Authorization"] = token
    } else {
        if(Vue.prototype.loginTimer != null) {
            clearInterval(Vue.prototype.loginTimer)
        }
    }

    return config
}, err => {
    store.commit('hideLoading')
    return Promise.reject(err)
})


instance.interceptors.response.use(res => {
    store.commit('hideLoading')
    const {code} = res.data;
    if(code == 1) {
        return res;
    } else if(code == 403) {
        /**
         * 验证是否使app登录
         * 通知app已登录过期
         */
        let type = localStorage.getItem("appLogin");
        // if(type) {
            //app接口
            // window.android.tokenInvalid();
        // }
        if(window.android != undefined) {
            window.android.tokenInvalid();
        }
        Toast.fail('已过期，请重新登录');
        //清除token
        localStorage.removeItem('token');
        localStorage.clear();
        window.location.href='#/user'
        // router.replace({
        //     path: '/login'
        //     //登录成功后跳入浏览的当前页面
        //     // query: {redirect: router.currentRoute.fullPath}
        // })
        return false;
        // next({
        //     path: '/User',
        //     query: {redirect: to.name,isShowModal:true} // 将跳转的路由path作为参数，登录成功后跳转到该路由
        // })
    } else if(code == 500) {
        Toast.fail('操作错误，请联系管理员');
        return false;
    } else {
        return res;
    }
    // if (code == 403) {
    //     Toast.fail('已过期，请重新登录');
    //     //清除token
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('userInfo');
    //     //跳转
    //     window.location.href='/User'
    //     // next({
    //     //     path: '/User',
    //     //     query: {redirect: to.name,isShowModal:true} // 将跳转的路由path作为参数，登录成功后跳转到该路由
    //     // })
    // } else if (code == 0){
    //     Toast.fail('操作错误，请联系管理员');
    //     return false;
    // } else {
    //     return res;
    // }
    // 什么时候执行的??    在接收到响应之前,在执行then之前
    // console.log("res", res); // 服务器响应的一些信息

     // 返回的这个res  被then方法的res形参接收了
}, err => {
    store.commit('hideLoading')
    return Promise.reject(err)
})

export default instance
