import Vue from 'vue';
import {
  Button,
  Stepper,
  Search,
  Swipe,
  SwipeItem,
  Lazyload,
  Image,
  Icon,
  Tab, 
  Tabs,
  Tag,
  List,
  Cell,
  CellGroup,
  DropdownMenu,
  DropdownItem,
  Empty,
  Toast,
  Tabbar,
  TabbarItem,
  Col,
  Row,
  Grid,
  GridItem,
  Form,
  Field,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  SwipeCell,
  ActionSheet,
  NavBar,
  Switch,
  Dialog,
  RadioGroup,
  Radio,
  AddressList,
  AddressEdit,
  CouponCell,
  CouponList,
  popup,
  Collapse, 
  CollapseItem,
  Progress,
  Popover,
  ShareSheet,
  ImagePreview,
  Divider,
  Circle,
  createApp,
} from 'vant';
// import 'vant/lib/button/style';
// import 'vant/lib/stepper/style';


// Vue.use(createApp);
Vue.use(Circle);
Vue.use(Divider);
Vue.use(ImagePreview);
Vue.use(ShareSheet);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(CollapseItem);
Vue.use(Collapse);
Vue.use(popup);
Vue.use(CouponList);
Vue.use(CouponCell);
Vue.use(AddressEdit);
Vue.use(AddressList);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(NavBar);
Vue.use(ActionSheet);
Vue.use(Button);
Vue.use(SwipeCell);
Vue.use(SubmitBar);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Sku);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.use(Field);
Vue.use(Form);
Vue.use(Stepper);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Image);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Empty);
Vue.use(Toast);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Grid);
Vue.use(GridItem);