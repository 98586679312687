import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowPopupShow: false,
    loadShow: false
  },
  mutations: {
    changeIsShowPopupShow(state, payload) {
      state.isShowPopupShow = payload
    },
    showLoading(state) {
      state.loadShow = true
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.loadShow = false
    },
  },
  actions: {},
  modules: {}
})